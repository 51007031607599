import { put } from "helpers";
import { IFeedback } from "types";
import { IRelatorioEstagio } from "types/entities/RelatorioEstagioTypes";

export async function markPostAsViwed(
  feedback: IFeedback,
  colaboradorId: number
) {
  if (feedback.colaborador_id == colaboradorId && !feedback.visualizado) {
    try {
      await put(`/feedback/${feedback.id}`, { ...feedback, visualizado: true });
    } catch (error) {
      console.log({ error });
    }
  }
}

export async function markRelatoriOEstagioPostAsViwed(
  relatorioEstagio: IRelatorioEstagio,
  colaboradorId: number
) {
  if (relatorioEstagio.colaborador_id == colaboradorId && !relatorioEstagio.visualizado) {
    try {
      await put(`/relatorio-estagio/${relatorioEstagio.id}`, { ...relatorioEstagio, visualizado: true });
    } catch (error) {
      console.log({ error });
    }
  }
}